<template>
  <div class="product-list">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="列表"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="addObj">添加</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 产品列表 -->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="middle" justify="start">
        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="search_op.search_name" @change="getList" clearable>
            <template slot="prepend">用户名</template>
          </el-input>
        </el-col>

        <el-col :span="4">
          <el-input placeholder="请输入内容" v-model="search_op.uuid" @change="getList" clearable>
            <template slot="prepend">uuid</template>
          </el-input>
        </el-col>

        <el-col :span="1.5">
          创建时间
        </el-col>
        <el-col :span="3">
          <el-date-picker
            @change="getList"
            v-model="search_op.create_time_range"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row :gutter="20" type="flex" align="middle" justify="start" class="mt15">
        <el-col :span="4">
          <el-select v-model="search_op.status" placeholder="请选择状态" @change="getList">
            <el-option
              v-for="item in status_arr"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-table
        :data="list"
        style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :data="props.row.skus"
              size="mini"
              :show-header="false"
              style="width: 100%">
              <el-table-column
                prop="sku_name"
                >
                <!-- 表格扩展表格 -->
                <template slot-scope="scope">
                  <el-row>
                    <el-col :span="24">
                      <template v-if="scope.row.sku_name">
                        {{scope.row.sku_name}}
                      </template>
                      <template v-else>
                        {{props.row.name}}
                      </template>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">{{scope.row.sku_code}}</el-col>
                  </el-row>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column
          prop="username"
          width="200"
          label="代理名">
        </el-table-column>

        <el-table-column
          prop="uuid"
          width="200"
          label="uuid">
        </el-table-column>

        <el-table-column
          prop="status"
          label="状态"
          width="80"
          >
          <template slot-scope="scope">
            <template v-if="scope.row.status==1">
              <el-tag type="info" style="margin-right:5px">启用</el-tag>
            </template>
            <template v-if="scope.row.status==0">
              <el-tag type="danger" style="margin-right:5px">关闭</el-tag>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="create_time_str"
          label="创建时间">
        </el-table-column>

        <el-table-column
          prop="recent_login_time"
          label="最近登录时间">
        </el-table-column>

        <el-table-column
          prop="comment"
          label="备注">
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_num"
      :page-sizes="[5,10,15,20]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
// import csv from "@/lib/arrObjToCsv.js"
// import util from "@/lib/util.js"
// import fileThum from "@/components/FileThum.vue"
// const audio = new Audio();
export default {
    components:{
      Title,
      // fileThum
    },
    name:'soundsList',
    data(){
      return {
        audio: {
            name: '',
            artist: '蜗牛音效',
            url: '',
            cover: '', // prettier-ignore
            // lrc: 'https://cdn.moefe.org/music/lrc/thing.lrc',
        },
        status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
          {name:'所有',value:null},
        ],
        platform_arr:[
          {name:'win32',value:'win32'},
          {name:'darwin',value:'darwin'},
          {name:'所有',value:''},
        ],
        list:[],
        category_list:[],
        page_num:1,
        page_size:5,
        total:0,
        search_op:{},
      }
    },
    methods:{
			confirm(ob,callback){
				this.$confirm(ob.msg, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: !ob.type?'warning':ob.type
				}).then(() => {
					callback()
				}).catch((e) => {
					console.error(e)
					this.$message({
						type: 'info',
						message: '已取消'
					});          
				});
			},
      getList(toS=true){
        if(toS){
          this.page_num = 1;
        }
        let op = {
          page_num:this.page_num,
          page_size:this.page_size
        }
        Object.assign(op,this.search_op)
        apis.agent.read(op).then(res=>{
          console.log('res',res)
          this.list = (res.data.data.list || []).map((v)=>{
            v.skus = [];
            return v;
          })
          this.total = res.data.data.count
          // let csvO = new csv([
          //   "name","tags","stock_sum"
          // ],this.list)
          // console.log('parse',csvO.parse())
          // console.log('parse',csvO.exportCsv())
        }).catch(err=>{
          console.log('err',err)
        })
      },
      getCategoryList(){
        apis.category.read(0).then(res=>{
          console.log('res',res)
          this.category_list = res.data.data
        }).catch(err=>{
          console.log('err',err)
        })
      },
      handleEdit(index, row) {
        this.$router.push({path:'/agents/update',params:{obj:row},query:{uuid:row.uuid}})
        console.log(index, row);
      },
      handleDelete(index, row) {
				let callback = ()=>{
          let op = {id:row.id}
          apis.sounds.remove(op).then(res=>{
            console.log('res',res)
          }).catch(err=>{
            console.log('err',err)
          })
				}
				this.confirm({msg:`确认使用删除当前音效[${row.name}]？`},callback)
      },
      handleSizeChange(val) {
        this.page_size = val
        this.getList(false)
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page_num = val
        this.getList(false)
        console.log(`当前页: ${val}`);
      },
      play(i,item){

        let full_path = `/apis${item.path}`;
				if(typeof item.hostname != "undefined" && item.hostname != '' && item.hostname !=null){
					full_path = `${item.hostname}/${item.path}`;
				}
				if(typeof item.full_path != "undefined" && item.full_path != '' && item.full_path !=null){
					full_path = item.full_path;
				}
        this.audio.url = full_path;
        this.audio.name = `${item.name}`;
        this.$refs.aplayer.play();
        // audio.play();
      },
      addObj(){
        this.$router.push({path:'/agents/add'})
      }
    },
    activated(){
      this.getList(false)
    },
    mounted(){
      this.getList(false)
    }
}
</script>

<style lang="scss" scoped>
.product-list{
  padding:15px;
}

</style>